import { useRouter } from "next/router";
import Script from "next/script";
import React, { useEffect } from "react";

// https://panda-program.com/posts/nextjs-google-analytics とnext公式を参考に。
const TAGMANAGER_ID = process.env.NEXT_PUBLIC_GOOGLE_TAGMANAGER_ID || "";
const CLARITY_ID = process.env.NEXT_PUBLIC_MICROSOFT_CLARITY_ID || "";

const existsTagManagerId = TAGMANAGER_ID !== "";
const existsClarityId = CLARITY_ID !== "";

// PVを測定する
const pageview = (path: string) => {
  (window as any).gtag("config", TAGMANAGER_ID, {
    page_path: path,
  });
};

// _app.tsx で読み込む
export const usePageView = () => {
  const router = useRouter();

  useEffect(() => {
    if (!existsTagManagerId) {
      return;
    }

    const handleRouteChange = (path: string) => {
      pageview(path);
    };

    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);
};

// https://nextjs.org/docs/basic-features/script#afterinteractive 外部Scriptの注入のdocument
export const AnalyticsTags = () => (
  <>
    {existsTagManagerId && (
      <>
        <Script async src={`https://www.googletagmanager.com/gtag/js?id=${TAGMANAGER_ID}`} strategy="afterInteractive" />
        <Script
          id="google-analytics"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', "${TAGMANAGER_ID}");
          `,
          }}
        />
      </>
    )}
    {existsClarityId && (
      <>
        <Script
          id="microsoft-clarity"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
             (function(c,l,a,r,i,t,y){
                 c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                 t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                 y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
             })(window, document, "clarity", "script", "${CLARITY_ID}");
          `,
          }}
        />
      </>
    )}
  </>
);
