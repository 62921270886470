export const DEFAULT_LOCALE = "ja";

export const DEFAULT_PAGE = 1;
// /supplier_user_invitationsのみPER_PAGEを50件にしている
export const DEFAULT_PER_PAGE = 10;

// IP制限動作確認用
export const LOCAL_STATIC_IP = "153.246.214.177";

// countries tableの日本のID
export const COUNTRY_JAPAN_ID = "109";

// price_registration_sourceの一覧表示の件数
export const PRICE_REGISTRATION_SOURCE_PER_PAGE = 200;

// Global Menuの高さ
export const AppBarHeight = 64;

// Global Menuの左に出るDrawerの幅
export const DrawerWidth = 260;
// 日本円のcurrency定義
// TODO: id指定ではなくcode指定に修正する
export const YEN = {
  id: "392",
  displayName: "JPY",
  currencyCode: "円",
};
