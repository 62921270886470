// 認証関連の関数などをまとめた

import type { GetServerSideProps } from "next";
import { createContext, useContext } from "react";
import { MeFieldFragment } from "graphql/generated";
import buildSSRGraphqlClient from "lib/ssrGraphqlClient";
import { getSdk } from "graphql/ssr.generated";
import { DEFAULT_LOCALE } from "lib/const";
import { fetchDict } from "lib/dict";
import { withPageAuthRequired as Auth0WithPageAuthRequired } from "@auth0/nextjs-auth0";
import { xReferer, reportToAppsignal } from "lib/appsignal";

export const MeContext = createContext<MeFieldFragment | null>(null);
// component内で、現在ログイン中のuserを取得したいときに呼ぶ関数
// providerはpages/_app.tsxに書いてあり、初期値はpages配下のgetServerSidePropsなどが返すme props
export const useMe = () => useContext(MeContext);

// NITOEL_DEV-511 の対応後、こちらは不要になると思うので、Backend側も合わせて対応すること(account_kindでの制御が不要になるはず)
function isMainAccount(me: MeFieldFragment | null): boolean {
  if (me === null) {
    return false;
  }

  return me.accountKind === "main";
}

function can(me: MeFieldFragment | null, resource: string, action: string): boolean {
  if (me === null) {
    return false;
  } else if (!me.mergedPermissions[resource]) {
    return false;
  }
  return me.mergedPermissions[resource][action] === 1;
}

// 認証が必要なページに噛ませる
// https://github.com/auth0/nextjs-auth0
// の同名関数をwrapしている
// 認証と同時にlocaleなども自動で設定してくれる
// 使用例: returnToを指定しなければ、そのページのurlが自動で入る
// export const getServerSideProps = withPageAuthRequired({
//   async getServerSideProps(ctx) {
//     return { props: { customProp: 'bar' } };
//   },
//   trans: {
//     func: serverSideTranslations,
//     nameSpaces: ["common", "someResouce"]
//   }
// });
type WithPageAuthRequiredOptions = {
  getServerSideProps?: GetServerSideProps;
  trans?: {
    func: (locale: string, nameSpaces: string[]) => any;
    nameSpaces: string[];
  };
  dicts?: string[];
};

function withPageAuthRequired(options: WithPageAuthRequiredOptions): GetServerSideProps {
  const { getServerSideProps, trans, dicts } = options;
  return Auth0WithPageAuthRequired({
    async getServerSideProps(context) {
      const client = buildSSRGraphqlClient(context);
      const sdk = getSdk(client);
      const dict = await fetchDict(context, dicts || []);
      let me = null;

      try {
        me = (await sdk.Me())?.me;

        let ret: any = { props: {} };
        if (getServerSideProps) {
          ret = await getServerSideProps(context);
        }

        if (trans) {
          const locale = me?.languageCode ? me.languageCode : DEFAULT_LOCALE;
          return {
            ...ret,
            props: {
              ...ret.props,
              ...(await trans.func(locale, trans.nameSpaces)),
              me,
              dict: dict,
            },
          };
        } else {
          return {
            ...ret,
            props: {
              ...ret.props,
              me,
              dict: dict,
            },
          };
        }
      } catch (error) {
        if (error instanceof Error) {
          if (error.message.startsWith("ip_not_allowed")) {
            return {
              redirect: {
                destination: `/error/ip_restricted?ip=${context.req.headers["x-forwarded-for"]}`,
                permanent: false,
              },
            };
          } else if (error.message.startsWith("archived")) {
            return {
              redirect: {
                destination: "/login",
                permanent: false,
              },
            };
          } else if (error.message.startsWith("permissionDeny")) {
            return {
              redirect: {
                destination: `/error/not_permitted`,
                permanent: false,
              },
            };
          } else {
            // 想定してない例外がおきたらthrow
            reportToAppsignal(error as Error, me, xReferer(context.req));
            throw error;
          }
        }
      }
    },
  });
}

const exportedObject = {
  isMainAccount,
  can,
  withPageAuthRequired,
};
export default exportedObject;
