// https://docs.appsignal.com/front-end/configuration
// 上の注釈の通り、client側のerror検知のためのAppsignal. ErrorBoundaryのpropsとして渡して使うのが基本。
// server側でもimportしてsendErrorすると使える。

// IncomingMessageはNextApiRequestのextends元。
// context.reqで呼ばれるものがNextApiRequest型でなかったので、継承元のこちらで型付けする。
import type { IncomingMessage } from "http";
import Appsignal from "@appsignal/javascript";

export const appsignal = new Appsignal({
  key: process.env.NEXT_PUBLIC_APPSIGNAL_FRONTEND_KEY,
  revision: process.env.NEXT_PUBLIC_GIT_COMMIT_SHA || "default_revision",
  namespace: `nitoelFrontend-${process.env.NEXT_PUBLIC_KIND || "local"}`,
});

// 誰の操作か、を取得するための関数
export const fetchMe = async (accessToken: string | undefined) => {
  if (!accessToken) return;

  const baseUrl = `${process.env.NEXT_PUBLIC_BACKEND_API_BASE_URL}/private_api`;
  return await fetch(baseUrl + "/me", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((response) => response.json())
    .catch(() => null);
};

export const xReferer = (req: IncomingMessage): string => {
  return (req.headers["x-nitoel-referer"] as string) || (req.headers["referer"] as string) || "";
};

type MeType = {
  id: string;
  name: string;
  email: string;
  tenantId: string;
};

export const appsignalTags = (me: MeType | undefined | null, referer: string): { [key: string]: string } => {
  if (me) {
    const { id, tenantId } = me;
    // NOTE: nameだけが何故かどうやっても渡すことができず(入れると通知が飛ばなくなる。keyをhogeとかに変えてもだめだった)一旦コメントアウトしておく。
    // let { email, name } = me;
    // name = name.split(" ")[0] + " XXX";
    // - https://docs.appsignal.com/guides/custom-data/tagging-request.html
    // あまり個人情報を送り込んでほしくないらしく、まぁ確かに全部渡す必要もないと思うので何となく分かる程度にマスクする。
    let { email } = me;
    email = "XXX@" + email.split("@")[1];
    return { adminUserId: id, tenantId, email, referer };
  } else {
    return { referer };
  }
};

export const reportToAppsignal = (err: Error, me: MeType | undefined | null, referer: string) => {
  appsignal.sendError(err, (span) => {
    const a = appsignalTags(me, referer);
    span.setTags(a);
  });
};
