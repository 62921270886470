import { GraphQLClient } from "graphql-request";
// https://github.com/prisma-labs/graphql-request

function buildSSRGraphqlClient(context: any) {
  return new GraphQLClient(`${process.env.NEXT_PUBLIC_FRONTEND_API_BASE_URL}/api/graphql_proxy`, {
    headers: {
      cookie: context.req.headers.cookie,
      "X-Nitoel-Forwarded-For": context.req.headers["x-forwarded-for"],
      // backendに渡るとすべて/graphqlになってしまうので、独自定義のheaderを用意してbackendにも渡す。
      "X-Nitoel-Referer": context.req.headers["referer"],
    },
  });
}

export default buildSSRGraphqlClient;
