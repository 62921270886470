import { createContext, useState, ReactNode, useContext } from "react";

export const InfoBarContext = createContext<any | null>(null);

export const useInfoBar = () => useContext(InfoBarContext);

export const InfoBarProvider = ({ children }: { children?: ReactNode }) => {
  const [infoBar, setInfoBar] = useState({ dataId: null, partId: null, barType: null });
  const contextValue = {
    infoBar: infoBar,
    setInfoBar: setInfoBar,
  };

  return <InfoBarContext.Provider value={contextValue}>{children}</InfoBarContext.Provider>;
};
