import { createContext, useContext } from "react";

type DictContent = Record<string, unknown>;

type DictContextType = {
  dict: DictContent;

  replacements: ReplacementsType;

  debug: boolean;
};

export const DictContext = createContext<DictContextType>({ dict: {}, replacements: {}, debug: false });

export type DFunction = (key: string, customReplacements?: ReplacementsType) => string;

export const useDict = () => {
  const { dict, replacements, debug } = useContext(DictContext);

  return {
    // NOTE: customReplacements は辞書の中で `{{arg1}}` と `{{arg2}}` などの置換対象を指定するためのもの
    // 例: d("key", { arg1: "xxx", arg2: "yyy" }) で辞書のkeyに対して`{{arg1}}`と`{{arg2}}`がある場合、それぞれ`xxx`と`yyy`に置換される
    d: (key: string, customReplacements?: ReplacementsType) => genD(dict, customReplacements || replacements, debug)(key),
  };
};

function genD(dict: DictContent, replacements: ReplacementsType, debug: boolean) {
  return (key: string) => d(key, dict, replacements, debug);
}

function d(key: string, dict: DictContent, replacements: ReplacementsType, debug: boolean): string {
  const master = findValue(key, dict["master"] as DictContent);
  const custom = findValue(key, dict["custom"] as DictContent);

  if (debug) {
    return `${custom}[${master}]`;
  }

  const value = custom || master || key;

  return applyReplacements(value, replacements);
}

function findValue(key: string, dict: DictContent) {
  let result: any = dict;

  if (key.split(":").length > 2) {
    return "multiple ns key is not supported" + key;
  }

  const [namespace, nestedKey] = key.split(":");
  const keys = [namespace, ...nestedKey.split(".")];

  // 各キーを辞書内で再帰的に検索
  for (const k of keys) {
    // 現在のキーが辞書内に存在するかどうかを確認
    if (result && typeof result === "object" && k in result) {
      result = result[k];
    } else {
      // キーが見つからない場合はnullを返す
      return null;
    }
  }
  return result;
}

export function fetchDict(context: any, namespaces: string[]) {
  return fetch(`${process.env.NEXT_PUBLIC_FRONTEND_API_BASE_URL}/api/dict?namespaces=${namespaces}`, {
    headers: { cookie: context.req.headers.cookie },
  }).then((res) => res.json());
}

export type ReplacementsType = { [key: string]: string };

function applyReplacements(str: string, replacements: ReplacementsType): string {
  return str.replace(/{{\s*(.*?)\s*}}/g, (_, p1) => replacements[p1] || `{{${p1}}}`);
}
